import moment from 'moment'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

function Percentiles(props) {
    if (props.data == null || props.data.length < 1) {
        return (<div></div>)
    }

    let startDate, endDate
    for (let item of props.data) {
        let d = moment(item.date)
        if (startDate == null || d < startDate) {
            startDate = d
        }

        if (endDate == null || d > endDate) {
            endDate = d
        }
    }

    const days = moment.duration(endDate.diff(startDate)).days()

    let datasets = { }

    let d = moment(startDate)
    for (let i = 0; i < days; i++) {
        for (let item of props.data) {

            if (Object.keys(datasets).indexOf(item.title) == -1) {
                datasets[item.title] = {
                    twentyFive: Array(days).fill(0),
                    fifty: Array(days).fill(0),
                    seventyFive: Array(days).fill(0)
                }
            }

            const parsedDate = moment(item.date)
            if (parsedDate.isSame(d)) {
                datasets[item.title]['twentyFive'][i] = item.twenty_five
                datasets[item.title]['fifty'][i] = item.fifty
                datasets[item.title]['seventyFive'][i] = item.seventy_five
            }
        }
        d.add(1, 'days')
    }
    
    let labels = []
    while (startDate < endDate) {
        labels.push(startDate.format("MMMM Do"))
        startDate.add(1, "days")
    }

    const colors = [
        'rgb(255, 99, 132)',
        'rgb(53, 162, 235)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ]

    let colorIndex = 0
    let sets = Object.keys(datasets).map((key, index) => {
        colorIndex += 3
        return [{
            label: `${key} 25`,
            data: datasets[key]['twentyFive'],
            borderColor: colors[colorIndex % colors.length],
            backgroundColor: colors[colorIndex % colors.length]
        }, {
            label: `${key} 50`,
            data: datasets[key]['fifty'],
            borderColor: colors[colorIndex+1 % colors.length],
            backgroundColor: colors[colorIndex+1 % colors.length]
        }, {
            label: `${key} 75`,
            data: datasets[key]['fifty'],
            borderColor: colors[colorIndex+2 % colors.length],
            backgroundColor: colors[colorIndex+2 % colors.length]
        }]
    })

    sets = sets.flat()

    const data = {
        labels,
        datasets: sets
    }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Percentile build speed',
          },
        },
        scales: {
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Seconds'
                }
            }
        }
      }

    return (
        <div><Line data={data} options={options} /></div>
    )
}

export default Percentiles