import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchBuilds, selectAllBuilds, selectBuildsStatus } from "../../reducers/BuildsSlice"
import BuildsListFeatureCSS from './BuildsListFeature.css'
import RelativeTime from '@yaireo/relative-time'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from "../Spinner/Spinner"
import { selectAllProjects, selectSelectedIdProject } from "../../reducers/ProjectsSlice"
import { selectAllOrganizations, selectSelectedOrganizationId } from "../../reducers/OrganizationsSlice"

function BuildsListFeature() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { orgId, projectId } = params

    const queryParams = new URLSearchParams(document.location.search)

    let sort = queryParams.get('sort') ?? "date"
    let direction = queryParams.get('direction') ?? "desc"
    const titleFilter = queryParams.get('title') ?? ""
    const outcomeFilter = queryParams.get('outcome') ?? ""
    const dateFilter = queryParams.get('dateRange') ?? ""

    // const selectedProjectId = useSelector(selectSelectedIdProject)

    useEffect(() => {
        if (params.projectId) {
            dispatch(fetchBuilds({
                sort, 
                direction, 
                title: titleFilter,
                outcome: outcomeFilter,
                date: dateFilter,
                projectId: params.projectId
            }))  
        }
    }, [sort, direction, titleFilter, outcomeFilter, dateFilter, params.projectId])

    const status = useSelector(selectBuildsStatus)
    const builds = useSelector(selectAllBuilds)
    const projects = useSelector(selectAllProjects)
    const organizations = useSelector(selectAllOrganizations)
    const selectedOrganizationId = useSelector(selectSelectedOrganizationId)

    if (status == 'loading') {
        return (<Spinner />)
    }

    const rows = builds.map((build) => {
        const minutes = Math.floor(build.seconds / 60)
        const seconds = Math.floor(build.seconds - minutes * 60)

        const relativeTime = new RelativeTime()
        const time = relativeTime.from(new Date(build.createdAt))
        const linkUrl = `/${orgId}/${projectId}/builds/${build.id}`

        return (
            <div className="row" key={build.id}>
                <div className="col-sm-2">
                    <Link to={linkUrl}>{build.title}</Link>
                </div>
                <div className="col-sm-2">
                    {build.success ? <i className="bi bi-check-circle"></i> : <i className="bi bi-x-circle"></i>}
                </div>
                <div className="col-sm-2">
                    {minutes}.{seconds} minutes
                </div>
                <div className="col-sm-2">
                    {build.hostname}
                </div>
                <div className="col-sm-2" title={build.createdAt}>
                    {time}
                </div>
            </div>
        )
    })

    const selectedProject = projects.find((project) => project.id == params.projectId)
    const selectedOrganization = organizations.find((organization) => organization.id == selectedOrganizationId)

    if (selectedProject && selectedOrganization) {
        var headline = (
            <h3 className="headline">{selectedProject.name} in {selectedOrganization.name}</h3>
        )
    }

    if (rows.length == 0) {
        return (<div>{headline} No results found. Please follow the <Link to="/setup">setup instructions</Link>.</div>)
    }

    function headerClicked(header) {
        if (sort == header) {
            direction = direction == "desc" ? "asc" : "desc"
        } else {
            sort = header
        }

        navigate(`/${orgId}/${projectId}?sort=${sort}&direction=${direction}&title=${titleFilter}&outcome=${outcomeFilter}&dateRange=${dateFilter}`)
    }

    const baseClass= "col-sm-2"
    const arrow = direction == "asc" ? "bi bi-arrow-up" : "bi bi-arrow-down"
    const titleClass = `${baseClass} ${sort == 'title' ? arrow : ''}`
    const outcomeClass = `${baseClass} ${sort == 'outcome' ? arrow : ''}`
    const durationClass = `${baseClass} ${sort == 'duration' ? arrow : ''}`
    const hostanemClass = `${baseClass} ${sort == 'hostname' ? arrow : ''}`
    const dateClass = `${baseClass} ${sort == 'date' ? arrow : ''}`

    return (
        <div className="BuildsListFeature">
            {headline}
            <div className="row header-row">
                <div className={titleClass} onClick={() => {headerClicked('title')}}>
                    Title
                </div>
                <div className={outcomeClass} onClick={() => {headerClicked('outcome')}}>
                    Outcome
                </div>
                <div className={durationClass} onClick={() => {headerClicked('duration')}}>
                    Duration
                </div>
                <div className={hostanemClass} onClick={() => {headerClicked('hostname')}}>
                    Hostname
                </div>
                <div className={dateClass} onClick={() => {headerClicked('date')}}>
                    Date
                </div>
            </div>
            {rows}
        </div>
    )
}

export default BuildsListFeature