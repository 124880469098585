import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchUserProjects = createAsyncThunk('projects/my/fetch', async () => {
    return await apiClient('me/projects')
})

export const setSelectedProject = createAction('projects/selected')

const projectsAdapter = createEntityAdapter()

const initialState = projectsAdapter.getInitialState({
    status: 'idle',
    selectedProjectId: null,
})

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProjects.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchUserProjects.fulfilled, (state, action) => {
                projectsAdapter.setMany(state, action.payload)
                state.status = 'idle'
                state.selectedProjectId = action.payload[0].id
            })
            .addCase(setSelectedProject, (state, action) => {
                state.selectedProjectId = action.payload.id
            })
    }
})

export default projectsSlice.reducer

export const { 
    selectAll: selectAllProjects, 
    selectById: selectProjectById, 
} = projectsAdapter.getSelectors((state) => state.projects)

export const selectSelectedIdProject = createSelector(state => state.projects,
    projects => projects.selectedProjectId
)
