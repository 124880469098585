import { useDispatch } from "react-redux"
import { loginRequest } from "../../reducers/UsersSlice"
import LoginFeatureCSS from "./LoginFeature.css"

function LoginFeature() {
    const dispatch = useDispatch()

    function login() {
        const obj = {
            email: document.getElementById("email").value,
            password: document.getElementById("password").value,
        }

        dispatch(loginRequest(obj))
    }

    return (
        <div className="login-feature">
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email address</label>
                <input type="email" className="form-control" id="email" placeholder="name@example.com" />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" />
            </div>
            <button className="btn btn-primary" onClick={login}>Log in</button>
        </div>
    )
}

export default LoginFeature