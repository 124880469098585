import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchCoverageSummary = createAsyncThunk('testRuns/coverage/summary/fetch', async (testRunId) => {
    return apiClient(`testRuns/${testRunId}/coverage`)
})


const slice = createSlice({
    name: 'coverageSummaries',
    initialState: {status: 'idle'},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoverageSummary.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchCoverageSummary.fulfilled, (state, action) => {
                state.status = 'idle'
                state.summary = action.payload
            })
    }
})

export default slice.reducer

export const selectCoverageSummaryStatus = createSelector(
    (state) => state.coverageSummaries,
    (coverageSummaries) => coverageSummaries.status
)

export const selectCoverageSummary = createSelector(
    (state) => state.coverageSummaries,
    (coverageSummaries) => coverageSummaries.summary
)