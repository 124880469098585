
function PerformanceFeature(props) {
    const log = props.log.log

    let targets = log.subSteps.slice(0)
    targets = targets.filter((target) => {
        return !target.fetchedFromCache
    })
    
    targets.sort((lhs, rhs) => {
        return rhs.duration - lhs.duration
    })
    targets = targets.slice(0, Math.min(10, targets.length))

    const slowestTargets = targets.map((target) => {
        let title = target.title
        if (title.indexOf('Build target') === 0) {
            title = title.substring(13)
        }
        return (
            <div>
                {title} - {Math.round(target.duration)} seconds
            </div>
        )
    })
    
    let swiftSteps = []
    let cSteps = []
    for (let target of log.subSteps) {
        if (target.fetchedFromCache) {
            continue
        }
        
        for (let step of target.subSteps) {
            if (step.detailStepType == "swiftAggregatedCompilation") {
                for (let swiftStep of step.subSteps) {
                    if (swiftStep.detailStepType == "swiftCompilation") {
                        swiftSteps.push(swiftStep)
                    }
                }
            } else if (step.detailStepType == "cCompilation") {
                cSteps.push(step)
            }
        }
    }

    swiftSteps.sort((lhs, rhs) => {
        return rhs.duration - lhs.duration
    })
    const slowestSwiftFiles = swiftSteps.slice(0, Math.min(swiftSteps.length, 20)).map((step) => {
        return (
            <div>{step.title} - {Math.round(step.duration)} seconds</div>
        )
    })

    cSteps.sort((lhs, rhs) => {
        return rhs.duration - lhs.duration
    })

    const slowestCFiles = cSteps.slice(0, Math.min(cSteps.length, 20)).map((step) => {
        return (
            <div>{step.title} - {Math.round(step.duration)} seconds</div>
        )
    })

    return (
        <div>
            <h2>Slowest Targets</h2>
            {slowestTargets}

            <h2>Slowest Swift files compilation</h2>
            {slowestSwiftFiles}

            <h2>Slowest C files compilation</h2>
            {slowestCFiles}
        </div>
    )
}

export default PerformanceFeature