function WarningsFeature(params) {
    const log = params.log.log

    let warnings = []
    for (let target of log.subSteps) {
        if (target.warningCount === 0) {
            continue
        }

        for (let step of target.subSteps) {
            for (let subStep of step.subSteps) {
                if (subStep.warningCount > 0) {
                    for (let warning of subStep.warnings) {
                        if (warning.documentURL) {
                            warnings.push((<div>{warning.title} <a href={warning.documentURL}>{warning.documentURL}</a> Line {warning.startingLineNumber} column {warning.startingColumnNumber}</div>))
                        } else {
                            warnings.push((<div>{warning.clangWarning}</div>))
                        }
                    }
                }
            }

        }
    }

    if (warnings.length === 0) {
        return (
            <div>No warnings in build</div>
        )
    }

    return (
        <div>
            {warnings}
        </div>
    )
}

export default WarningsFeature