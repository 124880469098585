import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchTestResultsForTestRun = createAsyncThunk('testResults/fetch', async (id) => {
    return await apiClient(`testRuns/${id}/results`)
})

export const fetchTestResultsForTest = createAsyncThunk('testResults/test/fetch', async (id) => {
    return await apiClient(`tests/${id}/results`)
})

export const toggleExpandTarget = createAction('toggleExpandTarget')

const testResultsAdapter = createEntityAdapter()

const initialState = testResultsAdapter.getInitialState({
    status: 'idle',
    expandedTargets: [],
})

const testResultsSlice = createSlice({
    name: 'testResults',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTestResultsForTestRun.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestResultsForTestRun.fulfilled, ((state, action) => {
                state.status = 'idle'
                testResultsAdapter.setMany(state, action.payload)
            }))
            .addCase(fetchTestResultsForTest.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestResultsForTest.fulfilled, ((state, action) => {
                state.status = 'idle'
                testResultsAdapter.setMany(state, action.payload)
            }))
            .addCase(toggleExpandTarget, ((state, action) => {
                const idx = state.expandedTargets.indexOf(action.payload)
                if (idx == -1) {
                    state.expandedTargets.push(action.payload)
                } else {
                    state.expandedTargets = state.expandedTargets.filter((item) => item != action.payload)
                }
            }))
    }
})

export default testResultsSlice.reducer

export const { 
    selectAll: selectAllTestResults, 
    selectById: selectTestResultById, 
} = testResultsAdapter.getSelectors((state) => state.testResults)

export const selectTestResultsStatus = createSelector(
    (state) => state.testResults,
    (testResults) => testResults.status
)

export const selectExpandedTargets = createSelector(
    (state) => state.testResults,
    (testResults) => testResults.expandedTargets
)
