import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

let isLoggedIn = checkACookieExists('vapor-session')

export const fetchMe = createAsyncThunk('me/fetch', async () => {
    return await apiClient('me')
})

export const loginRequest = createAsyncThunk('login', async (credentials) => {
    let formData = new FormData()
    formData.set('username', credentials.email)
    formData.set('password', credentials.password)
    return await apiClient('/admin/login', {
        payload: formData,
        method: "POST"
    })
})

export const changePasswordRequest = createAsyncThunk('changepassword', async (values) => {
    return await apiClient('me/changepassword', {
        payload: values,
        method: "POST"
    })
})

export const logoutOfApp = createAction('me/logout')

export const inviteUser = createAsyncThunk('invite', async (options) => {
    return await apiClient('me/invite', {
        payload: {
            email: options.email,
            orgId: options.orgId,
        },
        method: "POST"
    })
})

export const acceptInviteFetch = createAsyncThunk('acceptinvite', async (options) => {
    return await apiClient('me/acceptinvite', {
        payload: {
            name: options.name,
            password: options.password,
            key: options.key,
        },
        method: "POST"
    })
})

const usersSlice = createSlice({
    name: 'user',
    initialState: {
        isLoggedIn: isLoggedIn,
        user: null,
        status: 'idle',
        changePasswordErrorMessage: null,
        changePasswordError: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMe.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMe.fulfilled, (state, action) => {
                state.user = action.payload.data
                state.status = 'idle'
                console.log('fetchme fulfilled', action)
                state.isLoggedIn = true
            })
            .addCase(loginRequest.fulfilled, (state, action) => {
                if (action.payload.status == 200) {
                    state.isLoggedIn = true
                }
            })
            .addCase(changePasswordRequest.fulfilled, (state, action) => {
                state.changePasswordErrorMessage = action.payload.data.errorMessage
                state.changePasswordError = action.payload.data.error
            })
            .addCase(logoutOfApp, (state, action) => {
                document.cookie = 'vapor-session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.illuminatebuild.com';
                state.isLoggedIn = false
                state.user = null
            })
            .addCase(acceptInviteFetch.fulfilled, (state, action) => {
                state.isLoggedIn = true
            })
    }
})

export default usersSlice.reducer

// From: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#examples
function checkACookieExists(cookieName) {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${cookieName}=`))
}

export const selectUser = createSelector((state) => state,
    (state) => state.user
)

export const selectIsLoggedIn = createSelector((state) => state,
    (state) => state.user.isLoggedIn
)

export const selectChangePasswordErrorMessage = createSelector((state) => state.user,
    (user) => user.changePasswordErrorMessage
)

export const selectChangePasswordError = createSelector((state) => state.user,
    (user) => user.changePasswordError)
