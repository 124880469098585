import { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import 'daterangepicker'
import css from 'daterangepicker/daterangepicker.css'
import HeaderCSS from './Header.css'
import moment from 'moment'
import jQuery from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import { logoutOfApp, selectIsLoggedIn } from '../../reducers/UsersSlice'
import { fetchUserOrganizations, selectAllOrganizations, selectSelectedOrganizationId } from '../../reducers/OrganizationsSlice'
import { fetchUserProjects, selectAllProjects, selectSelectedIdProject, setSelectedProject } from '../../reducers/ProjectsSlice'

function Header() {
    const navigate = useNavigate()
    const datepicker = useRef(null)
    const dispatch = useDispatch()
    
    let pathSplit = window.location.pathname.split('/')
    if (pathSplit.length > 1) {
        if (pathSplit[0].length == 0) {
            pathSplit = pathSplit.slice(1)
        }

        var orgId = pathSplit[0]
        var projectId = pathSplit[1]
    }

    useEffect(() => {
        jQuery(datepicker.current).daterangepicker({
            timePicker: true,
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: 'M/DD/YYYY hh:mm A'
            }
        })
    })

    let isLoggedIn = useSelector(selectIsLoggedIn)

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchUserOrganizations())
            dispatch(fetchUserProjects())
        }
    }, [isLoggedIn])

    const organizations = useSelector(selectAllOrganizations)
    const projects = useSelector(selectAllProjects).sort((a, b) => {
        return a.name > b.name
    })

    if (projects.length > 1) {
        const projectListItems = projects.map((project) => {
            const projectPath = `/${project.organization.id}/${project.id}`
            return (
                <li key={project.id}><Link to={projectPath} className="dropdown-item">{project.name}</Link></li>
            )
        })
        var projectDropdown = (
            <div className="dropdown">
                <a href="#" className="dropdown-toggle nav-link" id="projectDropdown" data-bs-toggle="dropdown">
                    Projects
                </a>
                <ul className="dropdown-menu" aria-labelledby="projectDropdown">
                    {projectListItems}
                </ul>
            </div>
        )
    }

    if (organizations.length > 1) {
        const organizationsListItems = organizations.map((organization) => {
            return (
                <li key={organization.id}><a href="#" className="dropdown-item">{organization.name}</a></li>
            )
        })

        var organizationsDropdown = (
            <div className="dropdown">
                <a href="#" className="dropdown-toggle nav-link" id="projectDropdown" data-bs-toggle="dropdown">
                    Organizations
                </a>
                <ul className="dropdown-menu" aria-labelledby="projectDropdown">
                    {organizationsListItems}
                </ul>
            </div>
        )
    }

    const selectedProject = projects.find((project) => project.id == projectId)
    if (selectedProject && selectedProject.hasDocs && selectedProject.docsTarget) {
        const docsTarget = `https://illuminatebuild.com/api/docs/${selectedProject.id}/documentation/${selectedProject.docsTarget}`
        var documentationItem = (<li><a href={docsTarget} className="nav-link px-2 bi bi-book"> Documentation</a></li>)
    }

    if (selectedProject && selectedProject.hasTests) {
        const testsHref= `/${orgId}/${projectId}/testruns`
        var testsItem = (<li><Link to={testsHref} className="nav-link px-2 bi bi-check2-circle">Tests</Link></li>)
    }

    function toggleExpandedSearch(e) {
        e.preventDefault()
        const expandedSearch = document.getElementById('ExpandedSearch')
        expandedSearch.classList.toggle('hidden')
    }

    function searchClicked(e) {
        const outcomeFilter = document.getElementById('OutcomeFilter')
        let options = {
            title: document.getElementById('TitleFilter').value,
            outcome: outcomeFilter.options[outcomeFilter.selectedIndex].value,
            dateRange: datepicker.current.value,
        }

        let queryString = Object.keys(options).map(key => `${key}=${options[key]}`).join('&')
        navigate(`/${orgId}/${projectId}/?${queryString}`)
    }

    function logoutClicked() {
        document.cookie = 'vapor-session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        dispatch(logoutOfApp())
        navigate('/')
    }

    const dashboardPath = `/${orgId}/${projectId}/dashboard`
    const allBuildsPath = `/${orgId}/${projectId}/`
    const setupInstructionsPath = `/${orgId}/${projectId}/setup`
    const invitePath = `/${orgId}/${projectId}/invite`

    if (isLoggedIn) {
        return (
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <Link to={allBuildsPath} className="site-link fs-4">
                            <i className="bi bi-lamp fs-2"></i>
                            Illuminate
                        </Link>
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        </ul>
                        <ul className="nav col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <li><Link to={dashboardPath} className="nav-link px-2 bi bi-speedometer"> Dashboard</Link></li>
                            {documentationItem}
                            {testsItem}
                            <li><Link to={allBuildsPath} className="nav-link px-2 bi bi-list"> All Builds</Link></li>
                            <li><Link to="/" className="nav-link ps2 bi bi-search" onClick={toggleExpandedSearch}> Search</Link></li>
                            <li>{projectDropdown}</li>
                            <li>{organizationsDropdown}</li>
                            <li>
                                <div className="dropdown">
                                    <a href="#" className="dropdown-toggle d-block fs-5 mt-1" id="userDropdown" data-bs-toggle="dropdown">
                                        <i className="bi bi-person-circle"></i>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="userDropdown">
                                        <li><Link to="/changepassword" className="dropdown-item">Change Password</Link></li>
                                        <li><Link to={setupInstructionsPath} className="dropdown-item">Setup Instructions</Link></li>
                                        <li><Link to={invitePath} className="dropdown-item">Invite</Link></li>
                                        <li><a href="#" className="dropdown-item" onClick={logoutClicked}>Logout</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container hidden" id="ExpandedSearch">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-2">
                            Date
                            <input ref={datepicker} className="form-control form-control-sm" />
                        </div>
                        <div className="col-2">
                            Title
                            <input type="text" id="TitleFilter" className="form-control form-control-sm" />
                        </div>
                        <div className="col-2">
                            Hostname
                            <input type="text" id="HostnameFilter" className="form-control form-control-sm" />
                        </div>
                        <div className="col-2">
                            Outcome
                            <select className="form-select form-select-sm" id="OutcomeFilter">
                                <option value=""></option>
                                <option value='succeeded'>Succeeded</option>
                                <option value='failed'>Failed</option>
                            </select>
                        </div>
                        <div className="col-1">
                            <button className="btn btn-dark search-button" onClick={searchClicked}>Search</button>
                        </div>
                    </div>
                </div>
            </header>
        )
    } else {
        return (
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <Link to="#" className="site-link fs-4">
                            <i className="bi bi-lamp fs-2"></i>
                            Illuminate
                        </Link>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header
