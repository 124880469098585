import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchLastWeekAverages, fetchLastWeekBuildCounts, fetchMostRecentBuilds, fetchPercentiles, selectDashboard } from "../../reducers/DashboardSlice"
import Spinner from "../Spinner/Spinner"
import moment from "moment"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import BuildCounts from "./BuildCounts"
import MostRecentBuilds from "./MostRecentBuilds"
import DashboardFeatureCSS from "./DashboardFeature.css"
import Percentiles from "./Percentiles"
import { selectSelectedIdProject, selectAllProjects } from "../../reducers/ProjectsSlice"
import { selectAllOrganizations, selectSelectedOrganizationId } from '../../reducers/OrganizationsSlice'
import { useParams } from "react-router-dom"

function DashboardFeature() {
    const dispatch = useDispatch()
    const dashboard = useSelector(selectDashboard)
    const projects = useSelector(selectAllProjects)
    const organizations = useSelector(selectAllOrganizations)
    const selectedOrganizationId = useSelector(selectSelectedOrganizationId)
    const params = useParams()
    const selectedProjectId = params.projectId

    useEffect(() => {
        if (selectedProjectId) {
            dispatch(fetchLastWeekAverages(selectedProjectId))
            dispatch(fetchLastWeekBuildCounts(selectedProjectId))
            dispatch(fetchMostRecentBuilds(selectedProjectId))
            dispatch(fetchPercentiles(selectedProjectId))
        }
    }, [selectedProjectId])

    console.log(dashboard)

    if (dashboard.lastWeekAveragesStatus == 'loading' || 
        dashboard.lastWeekAverages == null ||
        dashboard.fetchLastWeekBuildCountsStatus == 'loading' ||
        dashboard.lastWeekBuildCounts == null ||
        dashboard.mostRecentBuildsStatus == 'loading' ||
        dashboard.mostRecentBuilds == null ||
        dashboard.percentilesStatus == 'loading' ||
        dashboard.percentiles == null) {
        return <Spinner />
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      )

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Average build speed over past 7 days',
          },
        },
        scales: {
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Seconds'
                }
            }
        }
      }

    let labels = []
    let dashboardData = {}
    for (const item of dashboard.lastWeekAverages) {
        const dateString = moment(item.date.split('T')[0]).format("MMMM Do YYYY")
        if (labels.indexOf(dateString) == -1) {
            labels.push(dateString)
        }

        if (!dashboardData[item.title]) {
            dashboardData[item.title] = []
        }
        dashboardData[item.title].push([dateString, item.avg])
    }

    const colors = [
        'rgb(255, 99, 132)',
        'rgb(53, 162, 235)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ]

    const datasets = Object.keys(dashboardData).map((key, index) => {

        return {
            label: key,
            data: dashboardData[key],
            borderColor: colors[index % colors.length],
            backgroundColor: colors[index % colors.length]
        }
    })
      
    const data = {
        labels,
        datasets: datasets
    }

    const selectedProject = projects.find((project) => project.id == selectedProjectId)
    const selectedOrganization = organizations.find((organization) => organization.id == selectedOrganizationId)

    if (selectedProject && selectedOrganization) {
        var headline = (
            <h3 className="headline">{selectedProject.name} in {selectedOrganization.name}</h3>
        )
    }

    return (
        <div className="container">
            <div className="row">{headline}</div>
            <div className="row">
                <div className="col-6">
                    <Line options={options} data={data}></Line>
                </div>
                <div className="col-6">
                    <BuildCounts data={dashboard.lastWeekBuildCounts}></BuildCounts>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <MostRecentBuilds data={dashboard.mostRecentBuilds} organization={selectedOrganization}></MostRecentBuilds>
                </div>
                <div className="col-6">
                    <Percentiles data={dashboard.percentiles}></Percentiles>
                </div>
            </div>
        </div>
    )
}

export default DashboardFeature