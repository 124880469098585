import { createSlice, createAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import apiClient from "../apiClient"

export const fetchLastWeekAverages = createAsyncThunk('lastweekaverages/fetch', async (projectId) => {
    return await apiClient(`dashboard/lastweekaverage/${projectId}`)
})

export const fetchLastWeekBuildCounts = createAsyncThunk('lastweekbuildcounts/fetch', async (projectId) => {
    return await apiClient(`dashboard/lastweekbuildcount/${projectId}`)
})

export const fetchMostRecentBuilds = createAsyncThunk('mostrecentbuilds/fetch', async (projectId) => {
    return await apiClient(`dashboard/last100builds/${projectId}`)
})

export const fetchPercentiles = createAsyncThunk('percentiles/fetch', async (projectId) => {
    return await apiClient(`dashboard/percentiles/${projectId}`)
})

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        lastWeekAveragesStatus: 'idle',
        lastWeekBuildCountsStatus: 'idle',
        mostRecentBuildsStatus: 'idle',
        percentilesStatus: 'idle'
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLastWeekAverages.pending, (state, action) => {
                state.lastWeekAveragesStatus = 'loading'
            })
            .addCase(fetchLastWeekAverages.fulfilled, (state, action) => {
                state.lastWeekAverages = action.payload
                state.lastWeekAveragesStatus = 'idle'
            })
            .addCase(fetchLastWeekBuildCounts.pending, (state, action) => {
                state.lastWeekBuildCountsStatus = 'loading'
            })
            .addCase(fetchLastWeekBuildCounts.fulfilled, (state, action) => {
                state.lastWeekBuildCounts = action.payload
                state.lastWeekBuildCountsStatus = 'idle'
            })
            .addCase(fetchMostRecentBuilds.pending, (state, action) => {
                state.mostRecentBuildsStatus = 'loading'
            })
            .addCase(fetchMostRecentBuilds.fulfilled, (state, action) => {
                state.mostRecentBuilds = action.payload
                state.mostRecentBuildsStatus = 'idle'
            })
            .addCase(fetchPercentiles.pending, (state, action) => {
                state.percentilesStatus = 'loading'
            })
            .addCase(fetchPercentiles.fulfilled, (state, action) => {
                state.percentiles = action.payload
                state.percentilesStatus = 'idle'
            })
    }
})

export default dashboardSlice.reducer

export const selectDashboard = createSelector((state) => state,
    (state) => state.dashboard
)