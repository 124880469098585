
function SummaryFeature(params) {
    const log = params.log.log

    const dump = log.subSteps.map((section) => {
        const subSections = section.subSteps.map((subSection) => {

            const subSteps = subSection.subSteps.map((subStep) => {
                return (
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{subStep.title} - {subStep.type} - {subStep.detailStepType}</div>
                )
            })

            return (
                <div>
                    &nbsp;&nbsp;{subSection.title} - {subSection.type} - {subSection.detailStepType}
                    {subSteps}
                </div>
            )
        })
        return (<div><b>{section.title} - {section.type}</b>{subSections}</div>)
    })

    let swiftFileCount = 0
    let swiftFilesCompiled = 0
    let cFileCount = 0
    let cFileCompiled = 0
    let targetCount = 0
    for (let target of log.subSteps) {
        if (target.type === "target" && target.fetchedFromCache === false) {
            targetCount++
        }

        for (let step of target.subSteps) {
            if (step.detailStepType == "cCompilation") {
                cFileCount++

                if (step.fetchedFromCache === false) {
                    cFileCompiled++
                }
            }

            for (let subStep of step.subSteps) {
                if (subStep.detailStepType == "swiftCompilation") {
                    swiftFileCount++
                    if (subStep.fetchedFromCache === false) {
                        swiftFilesCompiled++
                    }
                }
            }
        }
    }

    let buildTime = log.duration * 1000
    let buildTimeDate = new Date(buildTime)
    buildTime = buildTimeDate.toISOString().substr(11, 8)
    let split = buildTime.split(":")
    let hours = parseInt(split[0])
    let minutes = parseInt(split[1])
    let seconds = parseInt(split[2])

    let buildTimeText = ""
    if (hours > 0) {
        buildTimeText = `${hours} hours, `
    }

    buildTimeText += `${minutes} minutes and ${seconds} seconds`


    return (
        <div>
            <div className="row">
                <div className="col-2">Outcome</div>
                <div className="col-10">{log.buildStatus}</div>
            </div>
            <div className="row">
                <div className="col-2">Build Time</div>
                <div className="col-10">{buildTimeText}</div>
            </div>
            <div className="row">
                <div className="col-2">Number of Swift files compiled</div>
                <div className="col-10">{swiftFilesCompiled} of {swiftFileCount}</div>
            </div>
            <div className="row">
                <div className="col-2">Number of C files compiled</div>
                <div className="col-10">{cFileCompiled} of {cFileCount}</div>
            </div>
            <div className="row">
                <div className="col-2">Targets</div>
                <div className="col-10">{targetCount}</div>
            </div>
            {/* <div className="row">
                {dump}
            </div> */}
        </div>
    )
}

export default SummaryFeature