import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchFileCoverage = createAsyncThunk('fileCoverage/fetch', async (params) => {
    return await apiClient(`testRuns/${params.testRunId}/filecoverage?id=${params.id}&file=${params.file}`)
})

export const toggleExpandFile = createAction('toggleExpandFile')

const slice = createSlice({
    name: 'fileCoverage',
    initialState: {
        status: 'idle',
        testRunId: null,
        files: {},
        expandedFiles: []
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFileCoverage.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchFileCoverage.fulfilled, ((state, action) => {
                state.status = 'idle'
                const testRunId = action.meta.arg.testRunId
                if (state.testRunId != testRunId) {
                    state.files = {}
                    state.testRunId = testRunId
                }
                state.files[action.meta.arg.file] = action.payload
            }))
            .addCase(toggleExpandFile, ((state, action) => {
                const idx = state.expandedFiles.indexOf(action.payload)
                if (idx == -1) {
                    state.expandedFiles.push(action.payload)
                } else {
                    state.expandedFiles = state.expandedFiles.filter((item) => item != action.payload)
                }
            }))
    }
})

export default slice.reducer

export const selectFileCoverageStatus = createSelector(
    (state) => state.fileCoverage,
    (fileCoverage) => fileCoverage.status
)

export const selectFileCoverage = createSelector(
    (state) => state.fileCoverage,
    (fileCoverage) => fileCoverage.files
)

export const selectExpandedFiles = createSelector(
    (state) => state.fileCoverage,
    (fileCoverage) => fileCoverage.expandedFiles
)