import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchUserOrganizations = createAsyncThunk('organiztions/my/fetch', async () => {
    return await apiClient('me/organizations')
})

const organizationsAdapter = createEntityAdapter()

const initialState = organizationsAdapter.getInitialState({
    status: 'idle',
    selectedOrganizationId: null,
})

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserOrganizations.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchUserOrganizations.fulfilled, (state, action) => {
                organizationsAdapter.setMany(state, action.payload)
                state.status = 'idle'
                state.selectedOrganizationId = action.payload[0].id
            })
    }
})

export default organizationsSlice.reducer

export const { 
    selectAll: selectAllOrganizations, 
    selectById: selectOrganizationById, 
} = organizationsAdapter.getSelectors((state) => state.organizations)

export const selectSelectedOrganizationId = createSelector((state) => state.organizations,
    organizations => organizations.selectedOrganizationId
)