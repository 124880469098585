import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { acceptInviteFetch, selectIsLoggedIn } from "../../reducers/UsersSlice"

function AcceptInviteFeature() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isLoggedIn = useSelector(selectIsLoggedIn)
    if (isLoggedIn) {
        console.log('navigating to /')
        navigate('/')
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlSearchParams.entries())

    function acceptInvite() {
        const name = document.getElementById("name")
        const password = document.getElementById("password")

        dispatch(acceptInviteFetch({
            name: name.value,
            password: password.value,
            key: queryParams.key,
        }))
    }

    return (
        <div className="login-feature">
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" />
            </div>
            <button className="btn btn-primary" onClick={acceptInvite}>Accept Invite</button>
        </div>
    )
}

export default AcceptInviteFeature