
function OptimizationsFeature(props) {
    const log = props.log.log
    const build = props.build

    const usingZld = build.usingZld
    // let usingJemalloc = false

    // function forStep(item) {
    //     if (item.subSteps == null || item.subSteps.length == 0) {
    //         // look for optimization
    //         const detailStepType = item.detailStepType
    //         if (detailStepType == "other" || detailStepType == "linker") {
    //             if (item.signature.toLowerCase().indexOf('zld') === 0) {
    //                 usingZld = true
    //             }
    //         }

    //         // console.log(item)
    //     }

    //     for (let subStep of item.subSteps) {
    //         forStep(subStep)
    //     }
    // }

    // forStep(log)

    return (
        <div>
            <div><b>Using zld</b>: {usingZld ? 'true' : 'false'}</div>
            {/* <div><b>Using jemalloc</b>: {usingJemalloc ? 'true' : 'false'}</div> */}
        </div>
    )
}

export default OptimizationsFeature