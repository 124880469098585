import { useEffect } from "react"
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { selectAllOrganizations, fetchUserOrganizations } from "../../reducers/OrganizationsSlice"
import { selectAllProjects, fetchUserProjects } from "../../reducers/ProjectsSlice"

function LandingFeature() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const organizations = useSelector(selectAllOrganizations)
    const projects = useSelector(selectAllProjects)

    useEffect(() => {
        if (organizations.length == 0 && projects.length == 0) {
            dispatch(fetchUserOrganizations())
            dispatch(fetchUserProjects())
        }
    })

    if (projects.length > 0) {
        const project = projects.sort((a,b) => { return a > b })[0]
        navigate(`/${project.organization.id}/${project.id}`)
    }
    
    return (<div></div>)
}

export default LandingFeature