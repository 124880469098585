import axios from 'axios'

export default async function client(endpoint, options, requestOptions = {}) {
    let url = `https://illuminatebuild.com`
    // let url = `http://localhost:8080`

    if (endpoint.indexOf('/') != 0) {
        url += `/api/${endpoint}`
    } else {
        url += endpoint
    }

    requestOptions.withCredentials = true

    if (!options) {
        let response = await axios.get(url, requestOptions)
        return response.data
    } else {
        if (options.method === "POST") {
            let response = await axios.post(url, options.payload, requestOptions)
            return response
        } else if (options.method === "DELETE") {
            return await axios.delete(url, requestOptions)
        } else if (options.method === "PUT") {
            return await axios.put(url, options.payload, requestOptions)
        }
    }
}
