import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchTestRunsForProject = createAsyncThunk('testRuns/fetch', async (projectId) => {
    return await apiClient(`testRuns?projectId=${projectId}`)
})

export const fetchTestRun = createAsyncThunk('testRun/fetch', async (id) => {
    return await apiClient(`testRuns/${id}`)
})

const testRunsAdapater = createEntityAdapter()

const initialState = testRunsAdapater.getInitialState({
    status: "idle",
})

const testRunsSlice = createSlice({
    name: 'testRuns',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTestRunsForProject.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestRunsForProject.fulfilled, ((state, action) => {
                state.status = 'idle'
                testRunsAdapater.setMany(state, action.payload)
            }))
            .addCase(fetchTestRun.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestRun.fulfilled, ((state, action) => {
                state.status = 'idle'
                testRunsAdapater.setOne(state, action.payload)
            }))
    }
})

export default testRunsSlice.reducer

export const { 
    selectAll: selectAllTestRuns, 
    selectById: selectTestRunById, 
} = testRunsAdapater.getSelectors((state) => state.testRuns)

export const selectTestRunsStatus = createSelector(
    (state) => state.testRuns,
    (testRuns) => testRuns.status
)