import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchTestRunLogs = createAsyncThunk('testRun/fetch/logs', async (id) => {
    return await apiClient(`testRuns/${id}/logs`)
})

const testRunLogsAdapater = createEntityAdapter()

const initialState = testRunLogsAdapater.getInitialState({
    status: "idle",
})

const testRunLogsSlice = createSlice({
    name: 'testRunLogs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTestRunLogs.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestRunLogs.fulfilled, ((state, action) => {
                state.status = 'idle'
                testRunLogsAdapater.setOne(state, {id: action.meta.arg, log: action.payload})
            }))
    }
})

export default testRunLogsSlice.reducer

export const { 
    selectAll: selectAllTestRunLogs, 
    selectById: selectTestRunLogsById, 
} = testRunLogsAdapater.getSelectors((state) => state.testRunLogs)

export const selectTestRunLogsStatus = createSelector(
    (state) => state.testRunLogs,
    (testRunLogs) => testRunLogs.status
)