
function ErrorsFeature(params) {
    const log = params.log.log

    let errors = []
    for (let target of log.subSteps) {
        if (target.errorCount === 0) {
            continue
        }

        for (let step of target.subSteps) {
            for (let subStep of step.subSteps) {
                if (subStep.errorCount > 0) {
                    errors.push((<div>{subStep.errorCount} errors in {subStep.signature}</div>))
                    for (let error of subStep.errors) {
                        if (error.documentURL) {
                            errors.push((<div>{error.clangWarning} {error.title} <a href={error.documentURL}>{error.documentURL}</a> Line {error.startingLineNumber} column {error.startingColumnNumber}</div>))
                        } else {
                            errors.push((<div>{error.clangWarning}</div>))
                        }
                    }
                }
            }

        }
    }

    if (errors.length === 0) {
        return (
            <div>No errors in build</div>
        )
    }

    return (
        <div>
            {errors}
        </div>
    )
}

export default ErrorsFeature