import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import BuildsListFeature from './features/BuildsListFeature/BuildsListFeature'
import BuildFeature from './features/BuildFeature/BuildFeature'
import Header from './features/Header/Header'
import DashboardFeature from './features/DashboardFeature/DashboardFeature';
import LoginFeature from './features/LoginFeature/LoginFeature';
import ChangePasswordFeature from './features/ChangePasswordFeature/ChangePasswordFeature'
import SetupFeature from './features/SetupFeature/SetupFeature'
import LandingFeature from './features/LandingFeature/LandingFeature'
import { fetchMe, selectUser } from './reducers/UsersSlice'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TestRunsFeature from './features/TestRunsFeature/TestRunsFeature';
import TestRunFeature from './features/TestRunFeature/TestRunFeature';
import TestFeature from './features/TestFeature/TestFeature';
import InviteFeature from './features/InviteFeature/InviteFeature';
import AcceptInviteFeature from './features/AcceptInviteFeature/AcceptInviteFeature';

function App() {
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUser)

  useEffect(() => {
    dispatch(fetchMe())
  }, [userInfo.isLoggedIn])

  if (userInfo.isLoggedIn) {
    var routes = (
      <Routes>
        <Route path="/:orgId/:projectId/builds/:id/:type" element={<BuildFeature />} />
        <Route path="/:orgId/:projectId/builds/:id" element={<BuildFeature />} />
        <Route path="/:orgId/:projectId/dashboard" element={<DashboardFeature />} />
        <Route path="/changepassword" element={<ChangePasswordFeature />} />
        <Route path="/:orgId/:projectId/setup" element={<SetupFeature />} />
        <Route path="/:orgId/:projectId/testruns" element={<TestRunsFeature />} />
        <Route path="/:orgId/:projectId/testruns/:id" element={<TestRunFeature />} />
        <Route path="/:orgId/:projectId/testruns/:id/:screen" element={<TestRunFeature />} />
        <Route path="/:orgId/:projectId/tests/:id" element={<TestFeature />} />
        <Route path="/:orgId/:projectId/invite" element={<InviteFeature />} />
        <Route path="/:orgId/:projectId/" element={<BuildsListFeature />} />
        <Route path="/acceptinvite" element={<AcceptInviteFeature />} />
        <Route path="/" element={<LandingFeature />} />
      </Routes>
    )
  } else {
    routes = (
      <Routes>
        <Route path="/acceptinvite" element={<AcceptInviteFeature />} />
        <Route path="/" element={<LoginFeature />} />
      </Routes>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
          <div className="container" style={{marginBottom: "100px"}}>
          {routes}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
