import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { Bar } from 'react-chartjs-2'

function TimelineFeature(props) {
    
    const log = props.log.log

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      )

    const startTimestamp = log.startTimestamp

    const labels = log.subSteps.map((target) => {
        return target.title
    })

    let dataSeries = log.subSteps.map((target) => {
        // const start = new Date(target.startTimestamp * 1000).getTime()
        // const end = new Date(target.endTimestamp * 1000).getTime()
        const start = Math.max(0, target.startTimestamp - startTimestamp)
        const end = Math.max(0, target.endTimestamp - startTimestamp)
        console.log([start, end])
        // return [target.startTimestamp / 10000000, target.endTimestamp / 100000]
        return [start, end]
        //return [3, 5]
    })

    // dataSeries = dataSeries.slice(0, 3)

    const compilationSeries = log.subSteps.map((target) => {
        return [target.startTimestamp - startTimestamp, target.compilationEndTimestamp - startTimestamp]
    })

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
              borderWidth: 2,
            },
          },
        responsive: true,
        plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Build Chart',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || ''
                  console.log(context)
                  if (context.raw && context.raw.length > 1) {
                    const time = (context.raw[1] - context.raw[0]).toFixed(4)
                    label += `: ${time}`
                  }
                  return label
                }
              }
            }
          },
          scales: {
            yAxes: [{
                  ticks: {
                    beginAtZero: false
                }
              }],
          }
    }

    const data = {
        labels,
        datasets: [
            {
            label: 'Compilation Time',
            data: compilationSeries,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
            label: 'Total Time',
            data: dataSeries,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <div>
            <Bar options={options} data={data} />
        </div>
    )
}

export default TimelineFeature