import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"
import { fetchBuild, selectBuildById } from "../../reducers/BuildsSlice"
import { fetchLog, selectLogById } from "../../reducers/LogsSlice"
import SummaryFeature from "../SummaryFeature/SummaryFeature"
import ErrorsFeature from "../ErrorsFeature/ErrorsFeature"
import BuildFeatureCSS from "./BuildFeature.css"
import Spinner from "../Spinner/Spinner"
import WarningsFeature from "../WarningsFeature/WarningsFeature"
import PerformanceFeature from "../PerformanceFeature/PerformanceFeature"
import TimelineFeature from "../TimelineFeature/TimelineFeature"
import TreeFeature from "../TreeFeature/TreeFeature"
import OptimizationsFeature from "../OptimizationsFeature/OptimizationsFeature"

function BuildFeature() {
    const params = useParams()
    const dispatch = useDispatch()
    const { orgId, projectId } = params

    const build = useSelector((state) => selectBuildById(state, params.id))
    const log = useSelector((state) => selectLogById(state, params.id))

    useEffect(() => {
        console.log(params.id)
        dispatch(fetchBuild(params.id))
        dispatch(fetchLog(params.id))
    }, [params.id])

    console.log('log', log)
    if (build == null || log == null) {
        return (<div><Spinner /></div>)
    }

    const baseUrl = `/${orgId}/${projectId}/builds/${params.id}`
    const summaryLink = `${baseUrl}/`
    const errorsLink = `${baseUrl}/errors`
    const warningsLink = `${baseUrl}/warnings`
    const performanceLink = `${baseUrl}/performance`
    const timelineLink = `${baseUrl}/timeline`
    const treeLink = `${baseUrl}/tree`
    const optimizationsLink = `${baseUrl}/optimizations`

    switch (params.type) {
        case "errors":
            var body = <ErrorsFeature log={log} />
            break
        case "warnings":
            body = <WarningsFeature log={log} />
            break
        case "performance":
            body = <PerformanceFeature log={log} />
            break
        case "timeline":
            body = <TimelineFeature log={log} />
            break
        case "tree":
            body = <TreeFeature log={log} />
            break
        case "optimizations":
            body = <OptimizationsFeature log={log} build={build} />
            break
        default:
            body = <SummaryFeature log={log} />
            break
    }

    return (
        <div class="build-feature">
            <div className="row">
                <h1>{log.log.title}</h1>
            </div>
            <div className="row">
                <div className="col-2 build-nav">
                    <Link to={summaryLink}>Summary</Link><br />
                    <Link to={errorsLink}>Errors</Link><br />
                    <Link to={warningsLink}>Warnings</Link><br />
                    <Link to={performanceLink}>Performance</Link><br />
                    <Link to={timelineLink}>Timeline</Link><br />
                    <Link to={treeLink}>Full log</Link><br />
                    <Link to={optimizationsLink}>Optimizations</Link>
                </div>
                <div className="col-10">
                    {body}
                </div>
            </div>
            </div>
    )
}

export default BuildFeature