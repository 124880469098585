import { configureStore } from "@reduxjs/toolkit";
import buildsReducer from "./reducers/BuildsSlice"
import logsReducer from "./reducers/LogsSlice"
import dashboadReducer from "./reducers/DashboardSlice"
import userReducer from "./reducers/UsersSlice"
import organizationsReducer from './reducers/OrganizationsSlice'
import projectsReducer from './reducers/ProjectsSlice'
import testRunsReducer from './reducers/TestRunsSlice'
import testResultsReducer from './reducers/TestResultsSlice'
import testsReducer from './reducers/TestsSlice'
import testRunLogsReducer from './reducers/TestRunLogsSlice'
import coverageSummariesReducer from './reducers/CoverageSummary'
import fileCoverageReducer from './reducers/FileCoverage'
import fileContentsReducer from './reducers/FileContents'

const store = configureStore({
    reducer: {
        builds: buildsReducer,
        logs: logsReducer,
        dashboard: dashboadReducer,
        user: userReducer,
        organizations: organizationsReducer,
        projects: projectsReducer,
        testRuns: testRunsReducer,
        testResults: testResultsReducer,
        tests: testsReducer,
        testRunLogs: testRunLogsReducer,
        coverageSummaries: coverageSummariesReducer,
        fileCoverage: fileCoverageReducer,
        fileContents: fileContentsReducer,
    }
})

export default store