import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment'
  
function BuildCounts(props) {
    const dashboardData = props.data

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Build Counts over past last 7 days',
          },
        },
      }

    let labels = []
    let items = {}

    for (const item of dashboardData) {
        if (!items[item.title]) {
            items[item.title] = Array(7).fill(0)
        }
    }

    let day = moment().startOf('day').subtract(7, 'days')
    for (let i = 0; i < 7; i++) {
        labels.push(day.format("MMMM Do"))

        for (const item of dashboardData) {
            if (moment(item.date).startOf('day').isSame(day)) {
                items[item.title][i] = item.count
            }
        }

        day.add(1, 'days')
    }

    const colors = [
        'rgb(255, 99, 132)',
        'rgb(53, 162, 235)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ]

    const datasets = Object.keys(items).map((key, index) => {
        return {
            label: key,
            data: items[key],
            borderColor: colors[index % colors.length],
            backgroundColor: colors[index % colors.length]
        }
    })

    const data = {
        labels,
        datasets: datasets
    }

    return (
        <Bar options={options} data={data} />
    )
}

export default BuildCounts