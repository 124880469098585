import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectAllProjects, selectSelectedIdProject } from "../../reducers/ProjectsSlice"
import SetupFeatureCSS from "./SetupFeature.css"

function SetupFeature() {
    const params = useParams()
    const { orgId, projectId } = params
    const projects = useSelector(selectAllProjects)

    const selectedProject = projects.find((project) => {
        return project.id == projectId
    })

    if (!selectedProject) {
        return (<div></div>)
    }

    if (selectedProject.ghInstallationId) {
        var ccInstructions = (
            <ol>
                <li>Setup GitHub Action (TODO)</li>
            </ol>
        )
    } else {
        const ghState = encodeURIComponent(`projectId=${projectId}&orgId=${orgId}`)
        const ghUrl = `https://github.com/apps/illuminate-build/installations/new?state=${ghState}`
        ccInstructions = (
            <a href={ghUrl}>Install GitHub App</a>
        )
    }

    return (
        <div className="SetupFeature">
            <h4>Follow these instructions to setup Illuminate logviewer for {selectedProject.name}</h4>
            <ol>
                <li>
                    <code>xcodebuild test -scheme {selectedProject.docsTarget ?? "SCHEME_TO_USE"} -destination "platform=iOS Simulator,name=iPhone 8" -resultBundlePath TestResults -derivedDataPath results</code>
                </li>
                <li><code>cd TestResults</code></li>
                <li><code>zip -r ../testresults.zip *</code></li>
                <li><code>cd -</code></li>
                <li><code>HOSTNAME=$(hostname)</code></li>
                <li><code>curl -F "key={selectedProject.uploadKey}" -F "log=@testresults.zip;type=application/zip" -F "hostname=$HOSTNAME" https://illuminatebuild.com/testupload</code></li>
                <li><code>LOG=$(ls -drt results/Logs/Build/* | grep "xcactivitylog" | tail -n 1)</code></li>
                <li><code>curl -F "hostname=$HOSTNAME" -F "key={selectedProject.uploadKey}" -F "log=@$&#123;LOG&#125;" -F "zld=false" https://illuminatebuild.com/upload</code></li>
                {/* <li>
                    Download <a href="https://illuminatebuild.com/logviewer-setup">logviewer-setup</a> binary.
                </li>
                <li>
                    Run <code>./logviewer-setup logviewer --scheme {selectedProject.docsTarget ?? "SCHEME_TO_USE"} --xcodeproj-path PATH_TO_XCODE_PROJ_FILE --upload-key {selectedProject.uploadKey}</code>
                </li> */}
            </ol>
            <h4>Follow these instructions to setup Illuminate documentation hosting for {selectedProject.name}</h4>
            <ol>
                <li>
                    <code>xcodebuild docbuild -scheme {selectedProject.docsTarget ?? "SCHEME_TO_USE"} OTHER_DOCC_FLAGS="--output-path ../docs --hosting-base-path api/docs/{selectedProject.id}/"</code>
                </li>
                <li><code>cd docs</code></li>
                <li><code>zip -r ../docs.zip *</code></li>
                <li><code>cd -</code></li>
                <li><code>curl -F "key={selectedProject.uploadKey}" -F "docs=@docs.zip;type=application/zip" https://illuminatebuild.com/docsupload</code></li>
            </ol>
            <h4>Follow these instructions to set up Illuminate Code Coverage for {selectedProject.name}</h4>
            {ccInstructions}
        </div>
    )
}

export default SetupFeature