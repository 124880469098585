import TreeFeatureCSS from "./TreeFeature.css"

function TreeFeature(props) {
    const log = props.log.log

    function buildTree(item) {
        if (item.subSteps == null || item.subSteps.length == 0) {
            return (<div>{item.title}</div>)
        }

        let renders = []
        for (let subItem of item.subSteps) {
            renders.push(buildTree(subItem))
        }

        return (<div><b>{item.title}</b> <div className="sub">{renders}</div></div>)
    }

    const dump = buildTree(log)

    return (<div className="tree-feature">{dump}</div>)
}

export default TreeFeature