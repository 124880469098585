import { useDispatch, useSelector } from 'react-redux'
import { changePasswordRequest, selectChangePasswordError, selectChangePasswordErrorMessage } from '../../reducers/UsersSlice'
import ChangePasswordCSS from './ChangePasswordFeature.css'

function ChangePasswordFeature() {
    const dispatch = useDispatch()
    const serverErrorMessage = useSelector(selectChangePasswordErrorMessage)
    const error = useSelector(selectChangePasswordError)
    function buttonClicked() {
        const currentPassword = document.getElementById("currentPassword").value
        const newPassword1 = document.getElementById("newPassword1").value
        const newPassword2 = document.getElementById("newPassword2").value

        if (newPassword1 == newPassword2) {
            dispatch(changePasswordRequest({
                oldPassword: currentPassword,
                newPassword: newPassword1,
            }))
        } else {
            document.getElementById("Errors").innerHTML = "New Passwords do not match"
        }
    }

    if (error === false) {
        var successMessage = "Successfully Changed Password"
    }

    return (
        <div className="change-password">
            <div className="text-success">{successMessage}</div>
            <div className="text-danger" id="Errors">{serverErrorMessage}</div>
            <div className="mb-3">
                <label htmlFor="currentPassword" className="form-label">Current Password</label>
                <input type="password" className="form-control" id="currentPassword" />
            </div>
            <div className="mb-3">
                <label htmlFor="newPassword1" className="form-label">New Password</label>
                <input type="password" className="form-control" id="newPassword1" />
            </div>
            <div className="mb-3">
                <label htmlFor="newPassword2" className="form-label">Repeat New Password</label>
                <input type="password" className="form-control" id="newPassword2" />
            </div>
            <button className="btn btn-primary" onClick={buttonClicked}>Change Password</button>
        </div>
    )
}

export default ChangePasswordFeature