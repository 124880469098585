import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"
import { fetchTestResultsForTestRun } from "./TestResultsSlice"

export const fetchTest = createAsyncThunk('test/fetch', async (id) => {
    return await apiClient(`tests/${id}`)
})

const testAdapter = createEntityAdapter()

const initialState = testAdapter.getInitialState({
    status: 'idle',
})

const testSlice = createSlice({
    name: 'tests',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTest.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTest.fulfilled, ((state, action) => {
                state.status = 'idle'
                testAdapter.setOne(state, action.payload)
            }))
            .addCase(fetchTestResultsForTestRun.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchTestResultsForTestRun.fulfilled, ((state, action) => {
                state.status = 'idle'
                const newTests = action.payload.map((testResult) => {
                    return testResult.test
                })
                testAdapter.setMany(state, newTests)
            }))
    }
})

export default testSlice.reducer

export const { 
    selectAll: selectAllTests, 
    selectById: selectTestById, 
} = testAdapter.getSelectors((state) => state.tests)

export const selectTestStatus = createSelector(
    (state) => state.tests,
    (tests) => tests.status
)