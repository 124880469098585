import RelativeTime from '@yaireo/relative-time'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchCoverageSummary, selectCoverageSummary, selectCoverageSummaryStatus } from '../../reducers/CoverageSummary'
import { fetchFileContents, selectFileContentsFiles, selectFileContentsStatus } from '../../reducers/FileContents'
import { fetchFileCoverage, selectExpandedFiles, selectFileCoverage, selectFileCoverageStatus, toggleExpandFile } from '../../reducers/FileCoverage'
import { selectProjectById } from '../../reducers/ProjectsSlice'
import { fetchTestResultsForTestRun, selectAllTestResults, selectExpandedTargets, toggleExpandTarget } from '../../reducers/TestResultsSlice'
import { fetchTestRunLogs, selectTestRunLogsById } from '../../reducers/TestRunLogsSlice'
import { fetchTestRun, selectTestRunById, selectTestRunsStatus } from '../../reducers/TestRunsSlice'
import Spinner from '../Spinner/Spinner'
import TestRunFeatureCSS from './TestRunFeature.css'

function TestRunFeature() {
    const params = useParams()
    const { orgId, projectId, id, screen } = params
    const dispatch = useDispatch()
    const status = useSelector(selectTestRunsStatus)
    const testRun = useSelector((state) => selectTestRunById(state, id))
    let testResults = useSelector(selectAllTestResults)
    const log = useSelector((state) => selectTestRunLogsById(state, id))

    const coverageSummary = useSelector(selectCoverageSummary)
    const coverageSummaryStatus = useSelector(selectCoverageSummaryStatus)
    const expandedTargets = useSelector(selectExpandedTargets)
    const expandedFiles = useSelector(selectExpandedFiles)

    const fileContentsStatus = useSelector(selectFileContentsStatus)
    const fileContentsFiles = useSelector(selectFileContentsFiles)
    const fileCoverage = useSelector(selectFileCoverage)
    const fileCoverageStatus = useSelector(selectFileCoverageStatus)

    const project = useSelector((state) => selectProjectById(state, projectId))

    useEffect(() => {
        dispatch(fetchTestRun(id))
        dispatch(fetchTestResultsForTestRun(id))
        //dispatch(fetchTestRunLogs(id))
        dispatch(fetchCoverageSummary(id))
    }, [id])

    if (status == 'loading' || coverageSummaryStatus == 'loading' || !testRun) {
        return <Spinner />
    }

    testResults = testResults.filter((testResult) => testResult.testRun.id == id)

    const resultItems = testResults.map((testResult) => {
        const summaryLink = `/${orgId}/${projectId}/tests/${testResult.test.id}`
        // const outputLink = `/${orgId}/${projectId}/testruns/${id}/output/${testResult.id}`
        return (
            <div className="row" key={testResult.id}>
                <div className="col-sm-2">
                    {testResult.result}
                </div>
                <div className="col-sm-3">
                    <Link to={summaryLink}>{testResult.test.identifier}</Link>
                </div>
                {/* <div className="col-sm-1">
                    {testResult.test.isFlaky ? "Flaky" : "Stable"}
                </div> */}
                {/* <div className="col-sm-2">
                    <Link to={outputLink}>Output</Link>
                </div> */}
            </div>
        )
    })

    const startText = testRun.result == "failed" ? "Failed" : "Successful"
    const relativeTime = new RelativeTime()
    const headerText = (
        <div title={testRun.createdAt}>{startText} test run {relativeTime.from(new Date(testRun.createdAt))}</div>
    )

    if (log != null) {
        function getTestOutput(section) {
            let output = ""
            if (section.emittedOutput) {
                output += section.emittedOutput._value
            }

            if (section.subsections) {
                for (let subsection of section.subsections._values) {
                    output += getTestOutput(subsection)
                }
            }

            return output + "<br /><br />"
        }


        let output = ""
        for (let subsection of log.log.subsections._values) {
            output += getTestOutput(subsection)
        }

        var logItem = (
            <div>
                <h4>Test Output</h4>
                {log.log.title._value}
                <div dangerouslySetInnerHTML={{ __html: output }} />
            </div>
        )
    }

    function filePathToRepoPath(filePath, repoName) {
        const idx = filePath.lastIndexOf(repoName)
        if (idx == -1) {
            return filePath
        }

        return filePath.substring(idx + repoName.length)
    }

    if (coverageSummary != null) {
        function targetClicked(targetName, evt) {
            dispatch(toggleExpandTarget(targetName))
        }

        function fileClicked(filePath, fileName, expanded) {
            dispatch(toggleExpandFile(fileName))
            if (!expanded) {
                dispatch(fetchFileCoverage({
                    testRunId: id,
                    id: testRun.xcresultId,
                    file: filePath,
                }))

                dispatch(fetchFileContents({
                    file: filePathToRepoPath(filePath, project.ghRepository),
                    testRunId: id,
                    projectId,
                }))
            }
        }

        const targets = coverageSummary.targets.map((target) => {
            const files = target.files.map((file) => {
                const expanded = expandedFiles.indexOf(file.name) != -1
                const className = expanded ? "bi bi-arrow-down" : "bi bi-arrow-right"

                const repoPath = filePathToRepoPath(file.path, project.ghRepository)
                let fileBody = (<span></span>)
                if (expanded) {
                    if (fileContentsFiles[repoPath] != null && fileCoverageStatus == 'idle') {
                        const contents = atob(fileContentsFiles[repoPath])
                        const split = contents.split("\n")

                        if (split.length <= 1) {
                            return (<div class="no-coverage">Not able to retreive coverage for file - Is this in a pod?</div>)
                        }

                        const lines = "<div>" + split.map((line, idx) => {
                            const lineNumber = idx+1
                            let spaces = "&nbsp;&nbsp;&nbsp;&nbsp;"
                            if (lineNumber >= 10 && lineNumber < 100) {
                                spaces = "&nbsp;&nbsp;&nbsp;"
                            } else if (lineNumber >= 100 && lineNumber < 1000) {
                                spaces = "&nbsp;&nbsp;"
                            } else if (lineNumber >= 1000) {
                                spaces = "&nbsp;"
                            }

                            const lineCoverageData = fileCoverage[file.path].coverage[file.path].find((line) => line.line == lineNumber)
                            let coverageClass = "not-executable"
                            if (lineCoverageData && lineCoverageData.isExecutable) {
                                coverageClass = lineCoverageData.executionCount > 0 ? "executed" : "not-executed"
                            }
                            return `<div class="${coverageClass}">${lineNumber}:${spaces}<span>${line.replaceAll(" ", "&nbsp;")}</span></div>`
                        }).join("") + "</div>"


                        fileBody = (<div className="file-body" dangerouslySetInnerHTML={{__html: lines}}></div>)
                    } else {
                        fileBody = (<Spinner />)
                    }
                }

                return (
                    <div className="file" key={file.name}>
                        <div className="file-header" onClick={(evt) => fileClicked(file.path, file.name, expanded)}><i className={className} />{file.name} - {Math.round(file.lineCoverage * 100)}%</div>
                        {fileBody}
                    </div>
                )
            })

            const expanded = expandedTargets.indexOf(target.name) != -1
            const className = expanded ? "bi bi-arrow-down" : "bi bi-arrow-right"

            return (
                <div className="target" key={target.name}>
                    <b onClick={(evt) => targetClicked(target.name, evt)}><i className={className} />{target.name} - {Math.round(target.lineCoverage * 100)}%</b>
                    {expanded ? files : (<div></div>)}
                </div>
            )
        })
        var coverageItem = (
            <div>
                <h4>Coverage</h4>
                {coverageSummary.coveredLines} of {coverageSummary.executableLines} lines covered
                {targets}
            </div>
        )
    }

    var testsItem = (
        <div>
            <h4>Tests</h4>
            <div className="row">
            <div className='col-sm-2'><b>Result</b></div>
                <div className='col-sm-3'><b>Test Identifier</b></div>
                {/* <div className="col-sm-1"><b>Stability</b></div> */}
                {/* <div className="col-sm-2"><b>Test Output</b></div> */}
            </div>
            {resultItems}
        </div>
    )

    const testsLink = `/${orgId}/${projectId}/testruns/${id}/`
    const coverageLink = `/${orgId}/${projectId}/testruns/${id}/coverage`
    const logsLink = `/${orgId}/${projectId}/testruns/${id}/logs`
    let testLinkClassName = "btn btn-primary"
    let coverageLinkClassName = "btn btn-primary"
    let logsLinkClassName = "btn btn-primary"

    let content = (<div></div>)
    switch (screen) {
        case "logs":
            content = logItem
            logsLinkClassName = "btn btn-outline-primary"
            break
        case "coverage":
            content = coverageItem
            coverageLinkClassName = "btn btn-outline-primary"
            break
        default:
            content = testsItem
            testLinkClassName = "btn btn-outline-primary"
            break
    }

    return (
        <div id="TestRun">
            <h3>{headerText}</h3>
            <div className="subnav">
                <Link to={testsLink} className={testLinkClassName}>Tests</Link>
                <Link to={coverageLink} className={coverageLinkClassName}>Coverage</Link>
                {/* <Link to={logsLink} className={logsLinkClassName}>Logs</Link> */}
            </div>
            {content}
        </div>
    )
}

export default TestRunFeature