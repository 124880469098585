import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { inviteUser } from "../../reducers/UsersSlice"

function InviteFeature() {
    const dispatch = useDispatch()
    const params = useParams()
    const { orgId } = params
    function invite() {
        const email = document.getElementById("email")
        if (email.value) {
            dispatch(inviteUser({
                email: email.value,
                orgId,
            }))
        }
    }

    return (
        <div className="login-feature">
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email address</label>
                <input type="email" className="form-control" id="email" placeholder="name@example.com" />
            </div>
            <button className="btn btn-primary" onClick={invite}>Invite</button>
        </div>
    )
}

export default InviteFeature