import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, createAction } from "@reduxjs/toolkit"
import apiClient from "../apiClient"

export const fetchFileContents = createAsyncThunk('fileContents/fetch', async (params) => {
    return await apiClient(`testRuns/${params.testRunId}/filecontents?projectId=${params.projectId}&file=${params.file}`)
})

const slice = createSlice({
    name: "fileContents",
    initialState: {
        status: "idle",
        testRunId: null,
        files: {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFileContents.pending, ((state, action) => {
                state.status = 'loading'
            }))
            .addCase(fetchFileContents.fulfilled, ((state, action) => {
                state.status = 'idle'
                const testRunId = action.meta.arg.testRunId
                if (state.testRunId != testRunId) {
                    state.files = {}
                    state.testRunId = testRunId
                }
                state.files[action.meta.arg.file] = action.payload
            }))
    }
})

export default slice.reducer

export const selectFileContentsStatus = createSelector(
    (state) => state.fileContents,
    (fileContents) => fileContents.status
)

export const selectFileContentsFiles = createSelector(
    (state) => state.fileContents,
    (fileContents) => fileContents.files
)
