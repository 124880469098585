import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import client from "../apiClient"

const buildsAdapter = createEntityAdapter()

const initialState = buildsAdapter.getInitialState({
    status: 'idle',
})

export const fetchBuilds = createAsyncThunk('builds/fetch', async (options) => {
    console.log(options)
    let queryString = Object.keys(options).map(key => `${key}=${options[key]}`).join('&')

    // return await client(`builds?sort=${options.sort}&direction=${options.direction}&title=${options.title}&outcome=${options.outcome}`)
    return await client(`builds?${queryString}`)
})

export const fetchBuild = createAsyncThunk('build/fetch', async (id) => {
    return await client(`builds/${id}`)
})

const buildsSlice = createSlice({
    name: 'builds',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBuilds.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchBuilds.fulfilled, (state, action) => {
                buildsAdapter.setAll(state, action.payload)
                state.status = 'idle'
            })
            .addCase(fetchBuild.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchBuild.fulfilled, (state, action) => {
                buildsAdapter.setOne(state, action.payload)
                state.status = 'idle'
            })
        }
})

export default buildsSlice.reducer

export const { 
    selectAll: selectAllBuilds, 
    selectById: selectBuildById, 
} = buildsAdapter.getSelectors((state) => state.builds)

export const selectBuildsStatus = createSelector(
    (state) => state.builds,
    (builds) => builds.status
)