import RelativeTime from '@yaireo/relative-time'
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { fetchTestRunsForProject, selectAllTestRuns, selectTestRunsStatus } from "../../reducers/TestRunsSlice"
import Spinner from "../Spinner/Spinner"
import TestRunsCSS from "./TestRuns.css"

function TestRunsFeature() {
    const dispatch = useDispatch()
    const params = useParams()
    const { orgId, projectId } = params
    const testRuns = useSelector(selectAllTestRuns).filter((testRun) => {
        return testRun.project.id == projectId
    }).sort((a, b) => {
        return a.createdAt < b.createdAt
    })
    const status = useSelector(selectTestRunsStatus)

    useEffect(() => {
        dispatch(fetchTestRunsForProject(projectId))
    }, [projectId])

    if (status == 'loading') {
        return <Spinner />
    }
    
    const runs = testRuns.map((testRun) => {
        const relativeTime = new RelativeTime()
        const time = relativeTime.from(new Date(testRun.createdAt))
        const href = `/${orgId}/${projectId}/testruns/${testRun.id}`
        return (
            <div className="test-run row">
                <div className="col-sm-2">
                    <Link to={href}>{testRun.result}</Link>
                </div>
                <div className="col-sm-2">{time}</div>
            </div>
        )
    })

    return (
        <div id="TestRuns">
            <h3>Test Runs</h3>
            <div className="row">
                <div className="col-sm-2">Result</div>
                <div className="col-sm-2">Date</div>
            </div>
            {runs}
        </div>
    )
}

export default TestRunsFeature