import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchTestResultsForTest, selectAllTestResults } from '../../reducers/TestResultsSlice'
import { fetchTest, selectTestById, selectTestStatus } from '../../reducers/TestsSlice'
import Spinner from '../Spinner/Spinner'
import TestFeatureCSS from './TestFeature.css'

function TestFeature() {
    const dispatch = useDispatch()
    const params = useParams()
    const { orgId, projectId, id } = params

    const test = useSelector((state) => selectTestById(state, id))
    const status = useSelector(selectTestStatus)
    const results = useSelector(selectAllTestResults).filter((testResult) => {
        return testResult.test.id == id
    })

    useEffect(() => {
        dispatch(fetchTest(id))
        dispatch(fetchTestResultsForTest(id))
    }, [id])

    if (!test || status == 'loading') {
        return <Spinner />
    }

    const resultItems = results.map((testResult) => {
        const className = testResult.result
        const href = `/${orgId}/${projectId}/testruns/${testResult.testRun.id}`
        return (
            <Link to={href}>
                <div className={className}></div>
            </Link>
        )
    })

    return (
        <div id="TestFeature">
            Name: {test.name}<br />
            Identifier: {test.identifier}<br />
            Is Flaky: {test.isFlaky ? "Yes" : "No"}<br/>
            Results: <br />
            <div className="results">
                {resultItems}
            </div>
        </div>
    )
}

export default TestFeature