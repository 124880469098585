import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import client from "../apiClient"
import pako from "pako"
import * as fflate from 'fflate';

const logsAdapter = createEntityAdapter()

const initialState = logsAdapter.getInitialState({
    status: 'idle',
})

export const fetchLog = createAsyncThunk('logs/fetch', async (id) => {
    return await client(`logs/${id}`)
})

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchLog.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchLog.fulfilled, (state, action) => {
                function str2ab(str) {
                    var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
                    var bufView = new Uint8Array(buf);
                    for (var i=0, strLen=str.length; i < strLen; i++) {
                      bufView[i] = str.charCodeAt(i);
                    }
                    return buf;
                  }

                //   console.log(action.payload, new ArrayBuffer(action.payload))
                // console.log(fflate.decompressSync(action.payload))
                // const output = pako.inflate(str2ab(action.payload))
                // console.log(output)
                let payload = action.payload
                console.log('typeof', typeof payload)
                if (typeof payload == "string") {
             //       payload = payload.replace("\n", "").replace("\"", "").replace("schema\"", "\"schema\"")
                }
      //          console.log('payload', payload, JSON.parse(payload))
                logsAdapter.setOne(state, {
                    id: action.meta.arg,
                    log: payload
                })
                state.status = 'idle'
            })
        }
})

export default logsSlice.reducer

export const { 
    selectAll: selectAllLogs, 
    selectById: selectLogById, 
} = logsAdapter.getSelectors((state) => state.logs)

export const selectLogsStatus = createSelector(
    (state) => state.logs,
    (logs) => logs.status
)