import { useNavigate } from "react-router-dom"

function MostRecentBuilds(props) {
    const data = props.data
    const navigate = useNavigate()

    function navigateTo(item) {
        navigate(`/${props.organization.id}/${item.project.id}/builds/${item.id}`)
    }

    const tds = data.map((item) => {
        const successClass = item.success ? "success" : "fail"
        return (<td title={item.createdAt} className={successClass} onClick={() => navigateTo(item)}></td>)
    })

    const row1 = tds.slice(0, 25)
    const row2 = tds.slice(25, 50)
    const row3 = tds.slice(50, 75)
    const row4 = tds.slice(75, 100)

    return (
        <div className="most-recent-builds">
            <b>100 Most Recent Builds</b>
            <table border="1">
                <tr>{row1}</tr>
                <tr>{row2}</tr>
                <tr>{row3}</tr>
                <tr>{row4}</tr>
            </table>
        </div>
    )
}

export default MostRecentBuilds